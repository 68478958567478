import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';
import { ReactNode, useMemo } from 'react';
import { useStores } from 'src/components/common/root-store-provider/root-store-provider';
import { FlagsProvider } from './FlaggedUtil';
import { SCANNER_TEST_NAME } from 'src/constants/analytics';
export const FEATURE_FLAGS = (['scannerFeature', 'usFeature'] as const);
const startsWithB = (inputString: any): boolean => /^B/.test(inputString || '');
export const FeatureFlagProvider = observer(({
  children
}: {
  children: ReactNode;
}) => {
  const {
    quizStore: {
      countryCode
    },
    analyticsStore: {
      getCaseNameByTestName
    },
    authStore: {
      variant
    }
  } = useStores();
  const {
    query
  } = useRouter();
  const featuresFromQuery = useMemo(() => {
    const featuresFromStorage = typeof window !== 'undefined' && sessionStorage.getItem('features')?.split(',') || [];
    if (query.features) {
      const features = typeof query.features === 'string' ? query.features.split(',') : query.features;
      typeof window !== 'undefined' && sessionStorage.setItem('features', features.toString());
      return features;
    }
    return featuresFromStorage;
  }, [query.features]);
  const isScannerEnabled = startsWithB(getCaseNameByTestName(SCANNER_TEST_NAME));
  const isVariantUsEnabled = (variant === 'MimikaUS' || variant === 'googleUS') && countryCode === 'US';
  const features = useMemo((): Record<(typeof FEATURE_FLAGS)[number], boolean> => ({
    scannerFeature: isScannerEnabled,
    usFeature: isVariantUsEnabled,
    ...Object.fromEntries(featuresFromQuery.map(feature => [feature, true]))
  }), [isScannerEnabled, isVariantUsEnabled, featuresFromQuery]);
  return <FlagsProvider features={features}>{children}</FlagsProvider>;
});