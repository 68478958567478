import { reaction } from 'mobx';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { useFeature } from '@features/FeatureToggle';
import { useStores } from 'src/components/common/root-store-provider/root-store-provider';

const variantsSet = new Set([
  'variant20',
  'variant21',
  'variant22',
  'google21',
]);

export const useIsAutoRenewEnabled = () => {
  const {
    authStore: { variant },
  } = useStores();

  const isMimikaUSEnabled = useFeature('usFeature');

  const variantCondition = useMemo(
    () => variantsSet.has(variant ?? '') || isMimikaUSEnabled,
    [variant, isMimikaUSEnabled],
  );

  const [isAutoRenewEnabled, setIsAutoRenewEnabled] =
    useState(variantCondition);

  const updateIsAutoRenewEnabled = useCallback(
    (newVariant: string | undefined) => {
      setIsAutoRenewEnabled(
        variantsSet.has(newVariant ?? '') || isMimikaUSEnabled,
      );
    },
    [isMimikaUSEnabled],
  );

  useEffect(() => {
    const dispose = reaction(() => variant ?? '', updateIsAutoRenewEnabled, {
      fireImmediately: true,
    });

    return () => dispose();
  }, [variant, updateIsAutoRenewEnabled]);

  return isAutoRenewEnabled;
};
